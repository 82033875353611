import React from 'react'
import API from '../../app/api'
import Alert from 'sweetalert2'
import FormatLegacy from 'app/lib/format-legacy'
import { Link } from 'react-router-dom'
import Error from 'components/message/error'
import Empty from 'components/message/empty'
import Loading from 'components/message/loading'
import { HoverTip } from '@marketing-milk/frontend'
import { BetterNestAPI } from 'app/nestapi'

interface ITableProps {
  config: {
    class: string
    column: string
  }
  columns: Array<IColumn>
  data?: Array<object>
  to: string
  clearMessages?: Function
  filter?: IFilter
  extend?: boolean
  useNest?: boolean
}

export interface IColumn {
  text: string
  type?: string
  link?: any
  linkRef?: any
  column: string
  sortBy?: string
  parse?: string
  sortable?: boolean
  format?: string
  data?: any
  check?: any
  vars?: Array<IColumnVars>
  background?: string
  render?: undefined | Function
}

interface IColumnVars {
  value?: any
  icon?: undefined | string
  title?: undefined | string
}

interface IFilter {
  column: string
  value: string
}

interface ITableState {
  data: Array<object> | any
  dynamic: boolean
  filter: {
    active: boolean
    query: string
  }
  sort: {
    active: boolean
    column: string
    reverse: boolean
  }
  error: boolean
  page: number
  lock: boolean
  loading: boolean
}

type ActionIconType = {
  icon: string
  name: string
  tooltip?: boolean
}
const ActionIcon = ({ icon, name, tooltip = false }: ActionIconType) => {
  return !tooltip ? (
    <i className={`fal fa-${icon}`} />
  ) : (
    <HoverTip name={name} description={name}>
      <i className={`fal fa-${icon}`} />
    </HoverTip>
  )
}

class Table extends React.Component<ITableProps, ITableState> {
  state = {
    data: [],
    dynamic: true,
    filter: {
      active: true,
      query: '',
    },
    sort: {
      active: false,
      column: '',
      reverse: false,
    },
    error: false,
    page: 1,
    lock: false,
    loading: true,
  }

  componentWillMount() {
    if (!this.props.data) {
      return this.fetchAPI()
    } else {
      this.setState(_ => ({
        ..._,
        data: this.props.data,
        dynamic: false,
        loading: false,
      }))
    }
  }

  shouldComponentUpdate(props: ITableProps, state: ITableState) {
    if (state.page !== this.state.page) {
      return true
    } else if (state.filter !== this.state.filter) {
      return true
    } else if (state.sort !== this.state.sort) {
      return true
    } else if (state.lock !== this.state.lock) {
      return true
    } else if (state.data !== this.state.data) {
      return true
    } else if (props.data !== this.props.data) {
      return true
    } else return state.loading !== this.state.loading
  }

  componentDidUpdate(props: ITableProps, old: ITableState) {
    if (this.state.dynamic && !this.state.lock) {
      const now = this.state
      // Page Changed
      if (old.page !== now.page) this.reloadAPI()

      // Sort Changed
      if (old.sort !== now.sort) this.reloadAPI()

      // Filter changed
      if (old.filter !== now.filter) {
        if (now.filter.query) this.reloadAPI()
      }

      // Lock removed
      if (old.lock !== now.lock) this.reloadAPI()
    }

    // Data updated
    if (props.data !== this.props.data) {
      this.setState(_ => ({
        ..._,
        data: this.props.data,
        dynamic: false,
        loading: false,
      }))
    }

    // Filter updated
    if (old.filter !== this.state.filter) {
      this.setState(_ => ({
        ..._,
        page: 1,
      }))
    }
  }

  fetchAPI = async () => {
    let { to, extend, filter } = this.props

    const query = filter
      ? `page[number]=${this.state.page}&page[size]=30&filter[${filter!.column}]=${filter!.value}`
      : `page[number]=${this.state.page}&page[size]=30`

    to = extend ? `${to}&${query}` : `${to}?${query}`

    let result
    if (this.props.useNest) {
      const data = (await BetterNestAPI.get(to)).data
      result = data.body ?? data
    } else {
      result = await API.get(to)
    }

    this.setState(_ => ({
      ..._,
      data: result,
      loading: false,
    }))
  }

  reloadAPI = async () => {
    let { extend, to } = this.props
    const { sort } = this.state

    const filter = {
      props: this.props.filter,
      state: this.state.filter,
    }

    const query = filter.props
      ? `page[number]=${this.state.page}&page[size]=30&filter[${filter!.props.column}]=${
          filter!.props.value
        }`
      : `page[number]=${this.state.page}&page[size]=30`

    to = extend ? `${to}&${query}` : `${to}?${query}`

    if (filter.state.active && filter.state.query) {
      to += `&filter[global]=${filter.state.query}`
    }

    if (sort.active) {
      to += sort.reverse ? `&sort=${sort.column}` : `&sort=-${sort.column}`
    }

    this.toggleLoading()
    let result
    if (this.props.useNest) {
      result = (await BetterNestAPI.get(to)).data.body
    } else {
      result = await API.get(to)
    }

    this.setState(_ => ({
      ..._,
      data: result,
      loading: false,
    }))
  }

  prevPage = () => {
    if (this.state.page < 1) {
      return
    }

    this.setState(_ => ({
      ..._,
      data: [],
      loading: true,
      page: _.page - 1,
    }))
  }

  nextPage = () => {
    this.setState(_ => ({
      ..._,
      data: [],
      page: _.page + 1,
      loading: true,
    }))
  }

  toggleLoading = () => {
    this.setState(_ => ({
      ..._,
      loading: !_.loading,
    }))
  }

  toggleLock = (status: boolean) => {
    this.setState(_ => ({
      ..._,
      lock: status,
    }))
  }

  parseData = (column: string, i: number, parse: any = null, format: any = null) => {
    try {
      const { data } = this.state
      if (column && column === 'all') {
        return data[i]
      }
      if (column) {
        let text = null
        if (column.indexOf('.') > -1) {
          if (data[i][column.split('.')[0]] !== undefined) {
            if (parse)
              text = FormatLegacy[parse](
                data[i][column.split('.')[0]][column.split('.')[1]],
                format
              )
            if (!parse) text = data[i][column.split('.')[0]][column.split('.')[1]]
          }
        } else {
          if (data[i][column] !== undefined) {
            if (parse) text = FormatLegacy[parse](data[i][column], format)
            if (!parse) text = data[i][column]
          }
        }
        return `${text}`
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  changeQuery = event => {
    event.persist()
    this.setState(_ => ({
      ..._,
      filter: {
        ..._.filter,
        query: event.target.value,
      },
    }))
  }

  // Sort
  changeSort = column => {
    if (column.type !== 'buttons') {
      column = column.sortBy ? column.sortBy : column.column
      if (this.state.sort.column !== column) {
        this.setState(_ => ({
          ..._,
          sort: {
            active: true,
            column: column,
            reverse: false,
          },
        }))
      } else {
        this.setState(_ => ({
          ..._,
          sort: {
            ..._.sort,
            reverse: !_.sort.reverse,
          },
        }))
      }
    } else {
      Alert.fire('Cannot Sort', 'You cannot sort by that column', 'error')
    }
  }

  getSortIcon = column => {
    column = column.sortBy ? column.sortBy : column.column
    if (this.state.sort.column === column) {
      if (this.state.sort.reverse) {
        return 'fa fa-sort-down ml-2 text-info'
      } else {
        return 'fa fa-sort-up ml-2 text-info'
      }
    } else {
      return 'fa fa-sort ml-2 text-black-50'
    }
  }

  getClassName = i => {
    const { config } = this.props
    const { data } = this.state
    if (config) {
      if (data[i][config.column]) {
        return config.class
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  render() {
    const { columns } = this.props
    const { data, dynamic, filter, page, loading, error } = this.state
    if (loading) {
      return <Loading />
    } else if (error) {
      return <Error {...this.props} />
    } else {
      if ((data && data.length > 0) || filter.active) {
        return (
          <div>
            {dynamic && filter.active ? (
              <div className="row">
                <div className="col-md-6 mb-2">
                  <form onSubmit={() => this.toggleLock(false)}>
                    <input
                      className="form-control"
                      placeholder="Filter results"
                      value={filter.query}
                      onChange={this.changeQuery}
                      onFocus={() => this.toggleLock(true)}
                      onBlur={() => this.toggleLock(false)}
                    />
                  </form>
                </div>
              </div>
            ) : null}
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th className="hover-th" key={i}>
                        {column.text}
                        {column.type !== 'buttons' && column.sortable !== false ? (
                          <i
                            className={this.getSortIcon(column)}
                            onClick={() => this.changeSort(column)}
                          />
                        ) : null}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, i) => {
                  return (
                    <tr className={this.getClassName(i)} key={i}>
                      {columns.map((column, n) => {
                        if (column.type === 'plain') {
                          return (
                            <td data-title={column.text} key={n}>
                              {this.parseData(column.column, i, column.parse, column.format)}
                            </td>
                          )
                        } else if (column.type === 'buttons') {
                          return (
                            <td data-title={column.text} key={n}>
                              {column.data.map((button, k) => {
                                if (
                                  !button.check ||
                                  (button.check &&
                                    button.check.value ===
                                      parseInt(
                                        this.parseData(button.check.column, i) ||
                                          ((button.check.value === 'exists' &&
                                            !!this.parseData(
                                              button.check.column,
                                              i
                                            )) as unknown as string)
                                      ))
                                ) {
                                  if (typeof button.link === 'string') {
                                    if (button.link.indexOf('http') === -1) {
                                      return (
                                        <Link
                                          className={`m-portlet__nav-link btn m-btn m-btn--icon m-btn--icon-only m-btn--pill`}
                                          key={k}
                                          title={button.title ? button.title : ''}
                                          to={button.link.replace(
                                            '%',
                                            this.parseData(button.column, i)
                                          )}
                                          style={{ marginRight: '1%', display: 'inline-grid' }}
                                        >
                                          <ActionIcon
                                            icon={button?.icon}
                                            name={button?.name}
                                            tooltip={button?.tooltip}
                                          />
                                        </Link>
                                      )
                                    } else {
                                      return (
                                        <a
                                          className={`m-portlet__nav-link btn m-btn m-btn--icon m-btn--icon-only m-btn--pill`}
                                          key={k}
                                          title={button.title ? button.title : ''}
                                          href={button.link.replace(
                                            '%',
                                            this.parseData(button.column, i)
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ marginRight: '1%', display: 'inline-grid' }}
                                        >
                                          <ActionIcon
                                            icon={button?.icon}
                                            name={button?.name}
                                            tooltip={button?.tooltip}
                                          />
                                        </a>
                                      )
                                    }
                                  } else {
                                    return (
                                      <button
                                        className={`m-portlet__nav-link btn m-btn m-btn--icon m-btn--icon-only m-btn--pill navigation-link ${button.class}`}
                                        key={k}
                                        title={button.title ? button.title : ''}
                                        onClick={() =>
                                          button.link(
                                            this.parseData(button.column, i),
                                            this.parseData(button.second, i)
                                          )
                                        }
                                        style={{ marginRight: '1%' }}
                                      >
                                        <ActionIcon
                                          icon={button?.icon}
                                          name={button?.name}
                                          tooltip={button?.tooltip}
                                        />
                                      </button>
                                    )
                                  }
                                } else {
                                  return null
                                }
                              })}
                            </td>
                          )
                        } else if (column.type === 'icon') {
                          const value = this.parseData(column.column, i)
                          return (
                            <td data-title={column.text} key={n}>
                              {column.vars!.map(x => {
                                x.value = `${x.value}`
                                if (x.value.indexOf(value) > -1) {
                                  return (
                                    <i
                                      className={`fa fa-${x.icon}`}
                                      key={x.value}
                                      title={x.title}
                                    />
                                  )
                                } else {
                                  return null
                                }
                              })}
                            </td>
                          )
                        } else if (column.type === 'tag') {
                          return (
                            <td data-title={column.text} key={n}>
                              <i
                                className="fa fa-hashtag 2x mr-2"
                                style={{ color: data[i][column.background!] }}
                              />
                              {this.parseData(column.column, i, column.parse, column.format)}
                            </td>
                          )
                        } else if (column.type === 'link') {
                          return (
                            <td data-title={column.text} key={n}>
                              <Link
                                className={`m-portlet__nav-link link-brand`}
                                to={column.link.replace(
                                  '%',
                                  this.parseData(column.linkRef, i, column.parse)
                                )}
                                style={{ marginRight: '1%' }}
                              >
                                {this.parseData(column.column, i, column.parse, column.format)}
                              </Link>
                            </td>
                          )
                        } else if (column.type === 'ext_link') {
                          return (
                            <td data-title={column.text} key={n}>
                              <a
                                className={`m-portlet__nav-link link-brand`}
                                href={column.link.replace('%', this.parseData(column.linkRef, i))}
                                style={{ marginRight: '1%' }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.parseData(column.column, i, column.parse, column.format)}
                              </a>
                            </td>
                          )
                        } else if (column.type === 'custom') {
                          return (
                            <td data-title={column.text} key={n}>
                              {column.render ? column.render(this.parseData('all', i, null)) : null}
                            </td>
                          )
                        } else {
                          return null
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="text-right">
              <p>
                Showing {data.length} results on page {page}
              </p>
              {page > 1 ? (
                <button className="btn btn-info mr-1" onClick={this.prevPage}>
                  Previous
                </button>
              ) : null}
              {data.length === 30 ? (
                <button className="btn btn-primary" onClick={this.nextPage}>
                  Next
                </button>
              ) : null}
            </div>
          </div>
        )
      } else {
        return <Empty />
      }
    }
  }
}

export default Table
