import React from 'react'
import Components from './components'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Table from 'components/base/tables/basic'
import Loading from 'components/message/loading'
import { BusinessUser } from 'app/interface/data/business'
import UserActions from 'app/redux/actions/pages/business/users'
import Switch from 'react-switch'
import User from '../../../../../../../../../app/interface/data/user'

const header = [
  'Full Name',
  'Email',
  'Notification Email',
  'Receive Lead Emails',
  'Receive Status Emails',
  'Remove User?',
]

const Users = ({
  data: { generic },
  pages: {
    business: { users },
  },
}: Redux) => {
  const userList = generic.users.data.reduce((acc: (User & BusinessUser)[], cur) => {
    const match = users.find(u => u.user_id === cur.id)
    if (match) {
      return [...acc, { ...cur, ...match }]
    }
    return acc
  }, [])

  return generic.users.loading ? (
    <Loading />
  ) : (
    <div>
      <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Assigned Users</h4>
      <Components.UserSearch available={generic.users.data} />
      <div className="mt-4">
        <Table
          header={header}
          rows={userList.map(user => ({
            name: user.name,
            email: user.email,
            notification_email: user.notification_email,
            receive_leads: (
              <Switch
                className="float-right"
                onChange={() => {
                  UserActions.editUser(user.user_id, 'receive_leads', !user.receive_leads)
                }}
                checked={user.receive_leads}
                onColor="#a044ff"
              />
            ),
            receive_status: (
              <Switch
                className="float-right"
                onChange={() => {
                  UserActions.editUser(user.user_id, 'receive_status', !user.receive_status)
                }}
                checked={user.receive_status}
                onColor="#a044ff"
              />
            ),
            actions: (
              <button
                className="btn btn-danger btn-sm float-right"
                onClick={() => UserActions.delUser(user.user_id)}
              >
                <i className="fa fa-user-times" />
              </button>
            ),
          }))}
        />
      </div>
    </div>
  )
}

export default Connect(Users)
