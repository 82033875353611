import React from 'react'
import API from 'app/api'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import Form from 'components/reusable/form'
import Portlet from 'components/reusable/portlet'

class Create extends React.Component {
  state = {
    options: [
      {
        label: 'Phone Provider',
        value: 1,
      },
      {
        label: 'Population Density',
        value: 2,
      },
    ],
  }

  componentWillMount() {
    this.form = {
      config: {
        cancel: '/admin/business/tags/list',
      },
      groups: [
        {
          label: 'Tag Info',
          rows: [
            [
              {
                size: 'half',
                type: 'text',
                label: 'Label',
                column: 'label',
                rules: 'required|min:1|max:20',
              },
              {
                size: 'half',
                type: 'select',
                label: 'Type',
                column: 'type',
                options: () => {
                  return this.state.options
                },
                onChange: (data, field, save) => {
                  save(data.value, field)
                },
                find: x => {
                  return this.state.options.find(i => i === x.value)
                },
              },
            ],
            [
              {
                size: 'half',
                type: 'color_picker',
                label: 'Color',
                column: 'color_hex',
              },
            ],
          ],
        },
      ],
    }
  }

  save = async tag => {
    await API.post('business/tag', { tag: tag })
    toast.success('Business tag has been created')
    this.props.history.push(`/admin/business/tags/list`)
  }

  render() {
    return (
      <div className="col-md-8">
        <Portlet title="Tag Creator">
          <Form {...this} />
        </Portlet>
      </div>
    )
  }
}

export default withRouter(Create)
