import Info from './info'
import Groups from './groups'
import Features from './features'
import Stage from 'app/interface/data/funnel/stage'

export default {
  Info,
  Features,
  Groups,
}

export interface Component extends Stage {
  isDeveloper?: boolean
}
