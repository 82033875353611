import { UserService } from './User.types'
import { CreateUserDTO, exampleUser, UserWithBusiness, Auth0User } from '@marketing-milk/interfaces'

let exampleAuth0User: Auth0User

export class UserServiceMock implements UserService {
  async create(userDTO: CreateUserDTO) {
    return {
      errors: [],
      data: { user: exampleUser as UserWithBusiness, message: 'User successfully created' },
    }
  }
  async getAll() {
    return [exampleUser] as UserWithBusiness[]
  }
  async getAllAuth0Users() {
    return [exampleAuth0User] as Auth0User[]
  }
  async getByID(userID: number) {
    return exampleUser as UserWithBusiness
  }
  async updateByID(userID: number) {}
  async deleteByID(userID: number) {}
  async getAuth0User(email: string) {}
}
