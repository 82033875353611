import Axios from 'axios'
export const NestAPI = Axios.create({
  baseURL: `${process.env.REACT_APP_NEST_API}/`,
})

export const BetterNestAPI = Axios.create({
  baseURL: `${process.env.REACT_APP_NEST_API}/`,
})

// Flatten response, unless doNotIntercept is set.
NestAPI.interceptors.response.use(response => {
  // @ts-ignore
  return response.config.doNotIntercept ? response : response.data.body
})

export const setAdminNestToken = (token: string | null) => {
  if (token) {
    NestAPI.defaults.headers['Authorization'] = `Bearer ${token}`
    BetterNestAPI.defaults.headers['Authorization'] = `Bearer ${token}`
  } else {
    NestAPI.defaults.headers['Authorization'] = null
    BetterNestAPI.defaults.headers['Authorization'] = null
  }
}
