import Moment from 'moment'
import React, { useContext } from 'react'
import { Avatar, Chip } from '@material-ui/core'
import { GridCellParams } from '@material-ui/x-grid'
import { VersionType } from '../components/VersionType'
import { VersionStatus } from '../components/version-status'
import { useQueryStringParams } from '@marketing-milk/frontend'
import { EditUploadModal } from './edit-upload-modal/EditUploadModal'
import { Loading, MaterialDataTable } from '@marketing-milk/frontend'
import { previousUploadsContext } from './context/PreviousUploadsContext'
import { VerifyUploadModal } from './verify-upload-modal/VerifyUploadModal'
import { DownloadUploadButton } from './download-upload-button/DownloadUploadButton'
import { PreviousUploadsContextProvider } from './context/PreviousUploadsContext.provider'

function PreviousUploadsTableComponent() {
  const { uploads } = useContext(previousUploadsContext)
  // watch for specific upload in query string
  const queryParams = useQueryStringParams<{ uploadID?: string }>()
  const queryStringUpload = uploads.find(x => x.id === Number(queryParams?.uploadID))

  if (uploads === undefined) {
    return <Loading />
  }

  const flexNum = 1
  return (
    <>
      {queryStringUpload && (
        <VerifyUploadModal defaultOpen upload={queryStringUpload}>
          <></>
        </VerifyUploadModal>
      )}
      <MaterialDataTable
        sortingOrder={['asc', 'desc']}
        rows={uploads}
        rowHeight={38}
        headerHeight={50}
        density={'comfortable'}
        columnBuffer={10}
        columns={[
          {
            field: 'displayName',
            headerName: 'Upload Name',
            description: 'The name of the upload',
            type: 'string',
            flex: flexNum,
            editable: false,
          },
          {
            field: 'dataSource',
            headerName: 'Type',
            description: 'The type of data for an upload',
            type: 'string',
            editable: false,
            renderCell: ({ row }: GridCellParams) =>
              row.dataSource ? <VersionType dataSource={row.dataSource} /> : <>N/A</>,
          },
          {
            field: 'eventName',
            headerName: 'Offline Event',
            description: 'The affected offline event',
            type: 'string',
            flex: flexNum,
            editable: false,
          },
          {
            field: 'fileStatus',
            headerName: 'File Status',
            description: 'The status of the upload',
            type: 'string',
            flex: flexNum,
            editable: false,
            renderCell: ({ row }: GridCellParams) =>
              row.versions[0] ? <VersionStatus version={row.versions[0]} /> : <>N/A</>,
          },
          {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
            description: 'The user who created the upload',
            type: 'string',
            flex: flexNum,
            editable: false,
            renderCell: ({ row }: GridCellParams) => {
              if (!row.versions[0]?.submittedUser) {
                return <>N/A</>
              }

              const fullName = `${row.versions[0]?.submittedUser?.firstName} ${row.versions[0]?.submittedUser?.lastName}`
              return (
                <Chip
                  avatar={
                    <Avatar
                      alt={`${fullName}'s profile pic`}
                      src={row.versions[0]?.submittedUser?.profilePicture}
                    />
                  }
                  label={fullName}
                />
              )
            },
          },
          {
            field: 'uploadedAt',
            headerName: 'Uploaded At',
            description: 'When the upload was made',
            type: 'string',
            flex: flexNum,
            editable: false,
            renderCell: ({ row }: GridCellParams) => {
              if (!row.versions[0]?.dateUploaded) {
                return <>N/A</>
              }
              return <>{Moment(row.versions[0]?.dateUploaded).format('MM-DD-YYYY (hh:mmA)')}</>
            },
          },
          {
            field: 'reviewedBy',
            headerName: 'Reviewed By',
            description: 'The user who reviewed the upload',
            type: 'string',
            flex: flexNum,
            editable: false,
            renderCell: ({ row }: GridCellParams) => {
              if (!row.versions[0]?.reviewedUser) {
                return <>N/A</>
              }

              const fullName = `${row.versions[0]?.reviewedUser?.firstName} ${row.versions[0]?.reviewedUser?.lastName}`
              return (
                <Chip
                  avatar={
                    <Avatar
                      alt={`${fullName}'s profile pic`}
                      src={row.versions[0]?.reviewedUser?.profilePicture}
                    />
                  }
                  label={fullName}
                />
              )
            },
          },
          {
            field: 'reviewedAt',
            headerName: 'Reviewed At',
            description: 'When the upload was reviewed',
            type: 'string',
            flex: flexNum,
            editable: false,
            renderCell: ({ row }: GridCellParams) => {
              if (!row.versions[0]?.submittedAt) {
                return <>N/A</>
              }
              return <>{Moment(row.versions[0]?.submittedAt).format('MM-DD-YYYY (hh:mmA)')}</>
            },
          },
          {
            field: 'tools',
            headerName: 'Tools',
            description: '',
            type: 'string',
            width: 250,
            editable: false,
            renderCell: ({ row }: GridCellParams) => (
              <>
                <EditUploadModal upload={row as any} />
                <div className="ml-2">
                  <VerifyUploadModal upload={row as any} />
                </div>
                <div className="ml-2">
                  <DownloadUploadButton upload={row as any} />
                </div>
              </>
            ),
          },
        ]}
        checkboxSelection={false}
        pagination
        pageSize={10}
        isCellEditable={() => false}
      />
    </>
  )
}

export function PreviousUploadsTable() {
  return (
    <PreviousUploadsContextProvider>
      <PreviousUploadsTableComponent />
    </PreviousUploadsContextProvider>
  )
}
