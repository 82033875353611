import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'

const Basic = bindActions(
  () => ({
    // Update single column
    update: (state, key: string, value: any) => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          main: {
            ...state.pages.business.main,
            [key]:
              (key === 'pixel_id' || key === 'fb_page_id' || key === 'instagram_id') && !value
                ? null
                : value,
          },
        },
      },
    }),
  }),
  Store
)

export default Basic
