import React from 'react'
import API from 'app/api'
import Alert from 'sweetalert2'
import { toast } from 'react-toastify'
import Table from 'components/reusable/table'
import Portlet from 'components/reusable/portlet'

class List extends React.Component {
  state = {
    render: true,
  }

  componentWillMount() {
    this.buttons = [
      {
        to: '/admin/business/tags/create',
        class: 'btn btn-info',
        text: 'Create Tag',
      },
    ]
    this.columns = [
      {
        type: 'tag',
        text: 'Label',
        column: 'label',
        background: 'color_hex',
      },
      {
        type: 'plain',
        text: 'Type',
        column: 'type',
        parse: 'tag_type',
      },
      {
        type: 'plain',
        text: 'Created At',
        column: 'created_at',
        parse: 'date',
        format: 'MMM DD, YYYY',
      },
      {
        text: 'Actions',
        type: 'buttons',
        data: [
          {
            class: '',
            column: 'id',
            icon: 'pen-square',
            link: '/admin/business/tags/%/edit',
          },
          {
            class: 'text-danger',
            column: 'id',
            second: 'label',
            icon: 'trash',
            link: this.delete,
          },
        ],
      },
    ]
  }

  toggleRender = () => {
    this.setState({
      render: false,
    })
    this.setState({
      render: true,
    })
  }

  delete = async (id, name) => {
    let choice = await Alert.fire({
      title: 'Are you sure?',
      text: `You are about to delete ${name} permanently`,
      type: 'question',
      showCancelButton: true,
      cancelButtonColor: '#36a3f7',
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#f4516c',
      focusCancel: true,
    })

    if (choice.value) {
      await API.delete(`business/tag/${id}`)
      toast.success('Tag has been deleted')
      this.toggleRender()
    }
  }

  render() {
    const { buttons, columns } = this
    const { render } = this.state
    if (render) {
      return (
        <Portlet buttons={buttons} title="Business Tags">
          <Table columns={columns} to="business/tag/list" />
        </Portlet>
      )
    } else {
      return null
    }
  }
}

export default List
