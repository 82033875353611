import React from 'react'
import { NestAPI } from 'app/nestapi'
import { FileCardProps } from './'
import Download from 'js-file-download'
import { Tile } from 'components/generic'

export function DownloadExampleFile({ file }: FileCardProps) {
  async function downloadExample(): Promise<void> {
    const fileContents: Record<'data', File> = await NestAPI.get(`/uploads/examples/${file.key}`, {
      // @ts-ignore - doNotIntercept is a custom config option we use
      doNotIntercept: true,
    })
    Download(fileContents.data, `${file.name}.csv`)
  }

  return (
    <div className="mt-3" style={{ width: '100%' }}>
      <div className="center" style={{ width: '100%' }}>
        <span className="btn btn-info w-100" onClick={downloadExample}>
          {<i className={`fa fa-${file.icon}`} />} {file.name}
        </span>
      </div>
      <div className="text-left">{file.desc}</div>
    </div>
  )
}
