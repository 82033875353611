import React from 'react'
import Alert from 'sweetalert2'
import { toast } from 'react-toastify'
import Table from 'components/reusable/table'
import { businessService } from '@marketing-milk/frontend'

class List extends React.Component {
  state = {
    loading: false,
  }

  toggleLoading = () => {
    this.setState(_ => ({
      ..._,
      loading: !_.loading,
    }))
  }

  delete = async (id, name) => {
    let choice = await Alert.fire({
      title: 'Are you sure?',
      text: `You are about to delete ${name}. You can recover them later if needed.`,
      type: 'question',
      showCancelButton: true,
      cancelButtonColor: '#36a3f7',
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#f4516c',
      focusCancel: true,
    })

    if (choice.value) {
      await businessService.deleteBusinessByID(id)
      toast.success('Business has been deleted')
      this.props.onChange()
      this.toggleLoading()
      this.toggleLoading()
    }
  }

  config = {
    column: 'api_error',
    class: 'table-danger',
  }

  columns = [
    {
      text: 'Business',
      type: 'link',
      link: '/admin/business/beta/accounts/edit/%',
      linkRef: 'id',
      column: 'businessName',
    },
    {
      text: 'Branch',
      type: 'plain',
      column: 'branchName',
      sortBy: 'branchId',
    },
    {
      text: 'Pod',
      type: 'plain',
      column: 'podName',
      sortBy: 'podId',
    },
    {
      text: 'Start',
      type: 'plain',
      column: 'startDate',
      parse: 'date',
      format: 'MMM DD, YYYY',
    },
    {
      text: 'Actions',
      type: 'buttons',
      data: [
        {
          class: '',
          column: 'id',
          icon: 'tachometer',
          link: `${process.env.REACT_APP_DASHBOARD}/%/dashboard`,
          tooltip: true,
          name: 'Dashboard',
        },
        {
          class: '',
          column: 'id',
          icon: 'tools',
          link: `${process.env.REACT_APP_BUILDER}/%/campaigns`,
          tooltip: true,
          name: 'Ad Builder',
        },
        {
          class: 'text-danger',
          column: 'id',
          second: 'business_name',
          icon: 'trash',
          link: this.delete,
          tooltip: true,
          name: 'Delete Business',
        },
      ],
    },
  ]

  render() {
    const { loading } = this.state
    const { config, columns } = this
    const { filter } = this.props
    return !loading ? (
      <Table
        columns={columns}
        config={config}
        to="businesses/list"
        filter={filter}
        useNest={true}
      />
    ) : null
  }
}

export default List
