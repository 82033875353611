import React from 'react'
import Blocks from './blocks'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'

interface Props extends Redux {
  onSubmit: () => void
}

const Review = ({
  pages: {
    business: {
      main,
      funnel: { stages },
    },
  },
  core: { errors },
  onSubmit,
}: Props) => (
  <div>
    <h2>Review Your Business</h2>
    <p>Please ensure that all of the information is valid before saving.</p>
    <hr />
    <div className="kt-form__section kt-form__section--first">
      {errors.length === 0 ? (
        <div>
          <Blocks.Main main={main} />
          <Blocks.Funnel stages={stages} />
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          <div className="alert-text">
            <h4 className="alert-heading">There's a few problems</h4>
            <p>Your business couldn't be saved due to a few problems.</p>
            <hr />
            <ul>
              {errors.map(({ message }, i) =>
                message.length > 0 ? <li key={i}>{message}</li> : null
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
    <div className="kt-form__actions">
      <div className="text-right">
        <div
          className="btn btn-primary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
          onClick={onSubmit}
        >
          Save Business
        </div>
      </div>
    </div>
  </div>
)

export default Connect(Review)
