import { AxiosResponse } from 'axios'
import { NestAPI, BetterNestAPI } from '../../nestapi'
import { BusinessManagerService } from './BusinessManager.types'
import {
  Business,
  BusinessConfig,
  BusinessError,
  BusinessFBObjects,
  FBConnectedInstagramAccount,
  MatchRate,
} from '@marketing-milk/interfaces'

export class BusinessManagerServiceImplementation implements BusinessManagerService {
  getInstagramPagesByAccountID(fbAccountID: string): Promise<FBConnectedInstagramAccount[]> {
    return NestAPI.get(`fb-accounts/instagram`, {
      params: {
        fbAccountID,
      },
    })
  }

  async createBusiness(businessConfig: BusinessConfig): Promise<Business> {
    const response: Business = await NestAPI.post(`businesses/manage`, businessConfig)
    return response
  }

  async updateBusiness(businessID: number, businessConfig: BusinessConfig): Promise<Business> {
    const response: Business = await NestAPI.patch(
      `businesses/manage/${businessID}`,
      businessConfig
    )
    return response
  }

  async getHistoricalMatchRate(businessID: number, eventType: string): Promise<MatchRate> {
    const response: AxiosResponse = await BetterNestAPI.get(
      `businesses/${businessID}/funnel/historical-match-rate/${eventType}`
    )

    return response.data
  }

  async getFBConnectedAccounts(
    fbAccountID: string,
    businessID?: number,
    adAuthorizerID?: number
  ): Promise<BusinessFBObjects> {
    const response: BusinessFBObjects = await NestAPI.get(`businesses/manage/fb-objects`, {
      params: {
        fb_account_id: fbAccountID,
        business_id: businessID,
        fb_ad_authorizer_id: adAuthorizerID,
      },
    })

    return response
  }

  async resolveIssueWithBusiness(businessID: number, flag: BusinessError): Promise<boolean> {
    return await NestAPI.patch(`businesses/manage/${businessID}/remove-flag`, {
      flag,
    })
  }
}
