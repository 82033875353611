import Axios from 'axios'

const API = Axios.create({
  baseURL: process.env.REACT_APP_LEGACY_API ?? '/api',
})

export const setToken = (token: string | null) => {
  if (token) {
    API.defaults.headers['Authorization'] = `Bearer ${token}`
  } else {
    API.defaults.headers['Authorization'] = null
  }
}

export default API
