import React from 'react'
import API from 'app/api'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import Form from 'components/reusable/form'
import Loading from 'components/message/loading'
import Portlet from 'components/reusable/portlet'

class Edit extends React.Component {
  state = {
    options: [
      {
        label: 'Phone Provider',
        value: 1,
      },
      {
        label: 'Population Density',
        value: 2,
      },
    ],
    tag: {},
    loading: true,
  }

  componentWillMount() {
    this.form = {
      lifecycle: {
        mount: () => {
          return this.state.tag
        },
        update: tag => this.setState({ tag: tag }),
      },
      config: {
        cancel: '/admin/business/tags/list',
      },
      groups: [
        {
          label: 'Tag Info',
          rows: [
            [
              {
                size: 'half',
                type: 'text',
                label: 'Label',
                column: 'label',
                rules: 'required|min:1|max:20',
              },
              {
                size: 'half',
                type: 'select',
                label: 'Type',
                column: 'type',
                options: () => {
                  return this.state.options
                },
                onChange: (data, field, save) => {
                  save(data.value, field)
                },
                find: x => x.value === this.state.tag.type,
              },
            ],
            [
              {
                size: 'half',
                type: 'color_picker',
                label: 'Color',
                column: 'color_hex',
              },
            ],
          ],
        },
      ],
    }
    this.fetch()
  }

  fetch = async () => {
    const { id } = this.props.match.params
    let result = await API.get(`business/tag/${id}`)
    this.setState({
      tag: result,
      loading: false,
    })
  }

  save = async tag => {
    await API.patch(`business/tag/${tag.id}`, { tag: tag })
    toast.success('Tag has been updated')
  }

  render() {
    const { loading } = this.state
    return (
      <div className="col-md-8">
        <Portlet title="Tag Editor">{!loading ? <Form {...this} /> : <Loading />}</Portlet>
      </div>
    )
  }
}

export default withRouter(Edit)
