import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'
import { BusinessUser } from 'app/interface/data/business'

const Users = bindActions(
  store => ({
    addUser: (state: any, id: number, receive_leads: boolean = false) => {
      let users: BusinessUser[] = [].concat(state.pages.business.users)

      users.push({
        user_id: id,
        receive_leads,
        receive_status: false,
      })

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            users: users,
          },
        },
      })
    },

    editUser: (state: any, id: number, key: string, value: boolean) => {
      let users: BusinessUser[] = [].concat(state.pages.business.users)

      if (users.find(x => x.user_id === id)) {
        users.find(x => x.user_id === id)![key] = value
      }

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            users: users,
          },
        },
      })
    },

    delUser: (state: any, id: number) => {
      let users: BusinessUser[] = [].concat(state.pages.business.users)
      users = users.filter(x => x.user_id !== id)
      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            users: users,
          },
        },
      })
    },
  }),
  Store
)

export default Users
