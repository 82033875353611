import { AxiosResponse } from 'axios'
import { UserService } from './User.types'
import { NestAPI } from '@marketing-milk/frontend'
import { BetterNestAPI } from '../../nestapi'
import {
  CreateUserDTO,
  UpdateUserDTO,
  UserWithBusiness,
  NewUserResponse,
  PostResponse,
  Auth0User,
} from '@marketing-milk/interfaces'

export class UserServiceImplementation implements UserService {
  async create(userDTO: CreateUserDTO): Promise<PostResponse<NewUserResponse>> {
    const newUser: AxiosResponse<PostResponse<NewUserResponse>> = await BetterNestAPI.post(
      'users',
      userDTO
    )
    return newUser.data
  }
  async getAll() {
    const users: AxiosResponse<UserWithBusiness[]> = await NestAPI.get('users')
    return users.data
  }
  async getAllAuth0Users() {
    const users: AxiosResponse<Auth0User[]> = await NestAPI.get('users/auth0/users')
    return users.data
  }
  async getByID(userID: number) {
    const users: AxiosResponse<UserWithBusiness> = await NestAPI.get(`users/${userID}`)
    return users.data
  }
  async updateByID(userID: number, userDTO: UpdateUserDTO) {
    await NestAPI.patch(`users/${userID}`, userDTO)
  }
  async deleteByID(userID: number) {
    await NestAPI.delete(`users/${userID}`)
  }

  async getAuth0User(email: string) {
    const user: AxiosResponse<any> = await NestAPI.post(`users/auth0`, { email })
    return user.data
  }
}
