import API from 'app/api'
import Store from 'app/redux/store'
import Redux from 'app/interface/redux'
import User from 'app/interface/data/user'
import { bindActions } from 'redux-zero/utils'

const Master = bindActions(
  store => ({
    init: async (): Promise<void> => {
      const result: User[] = await API.get('user/list')
      const state: Redux = store.getState()
      store.setState({
        data: {
          ...state.data,
          generic: {
            ...state.data.generic,
            users: {
              data: result,
              loading: false,
              timestamp: +new Date(),
            },
          },
        },
      })
    },
  }),
  Store
)

export default Master
