import { AxiosResponse } from 'axios'
import { NestAPI } from '@marketing-milk/frontend'
import { Auth0Role, Auth0RolePermission, User } from '@marketing-milk/interfaces'

export interface RoleService {
  getAllRoles(): Promise<Auth0Role[]>
  getRoleByID(roleID: string): Promise<Auth0Role>
  createRole(userDTO: Auth0Role): Promise<Auth0Role>
  updateRole(roleID: string, roleDTO: Auth0Role): Promise<Auth0Role>
  deleteRole(roleID: string): void
  addRolePermissions(roleID: string, permissions: Auth0RolePermission[]): Promise<void>
  deleteRolePermissions(roleID: string, permissions: Auth0RolePermission[]): Promise<void>
  getRolePermissions(roleID: string): Promise<Auth0RolePermission[]>
  getRoleUsers(roleID: string): Promise<User[]>
  addUsersToRole(roleId: string, userIds: number[]): Promise<void>
  removeRoleFromUser(roleId: string, userId: number): Promise<void>
  addRoleUsers(roleID: string, auth0UserIds: string[]): Promise<void>
  getAllPermissions(): Promise<Auth0RolePermission[]>
  removeRolesFromUser(auth0UserId, authORoles: Auth0Role[]): Promise<void>
  addRolesToAuth0User(userId: number, auth0Roles: Auth0Role[]): Promise<void>
  addRolesToPreSignupUser(email: string, authORoles: Auth0Role[]): Promise<void>
}

export class RoleServiceImplementation implements RoleService {
  // All Auth0 Roles
  async getAllRoles() {
    const roles: AxiosResponse<Auth0Role[]> = await NestAPI.get(`auth0/roles`)
    return roles.data
  }

  // Specific Role Endpoints
  async getRoleByID(roleID: string) {
    const role: AxiosResponse<Auth0Role> = await NestAPI.get(`auth0/roles/${roleID}`)
    return role.data
  }
  async createRole(roleDTO: Auth0Role): Promise<Auth0Role> {
    const newRole: AxiosResponse<Auth0Role> = await NestAPI.post('auth0/roles', roleDTO)
    return newRole.data
  }
  async updateRole(roleID: string, roleDTO: Auth0Role): Promise<Auth0Role> {
    const role: AxiosResponse<Auth0Role> = await NestAPI.patch(`auth0/roles/${roleID}`, roleDTO)
    return role.data
  }
  async deleteRole(roleID: string) {
    await NestAPI.delete(`auth0/roles/${roleID}`)
  }

  // Role Permission Endpoints
  async getRolePermissions(roleID: string): Promise<Auth0RolePermission[]> {
    const permissions: AxiosResponse<Auth0RolePermission[]> = await NestAPI.get(
      `auth0/roles/${roleID}/permissions`
    )
    return permissions.data
  }
  async addRolePermissions(roleID: string, permissions: Auth0RolePermission[]) {
    await NestAPI.post(`auth0/roles/${roleID}/permissions`, permissions)
  }
  async deleteRolePermissions(roleID: string, permissions: Auth0RolePermission[]) {
    await NestAPI.delete(`auth0/roles/${roleID}/permissions`, { data: permissions })
  }

  // Role User Endpoints
  async getRoleUsers(roleID: string): Promise<User[]> {
    const users: AxiosResponse<User[]> = await NestAPI.get(`auth0/roles/${roleID}/users`)
    return users.data
  }

  async addUsersToRole(roleId: string, userIds: number[]): Promise<void> {
    await NestAPI.post(`auth0/roles/${roleId}/users`, {
      userIds: userIds,
    })
  }

  async removeRoleFromUser(roleId: string, userId: number): Promise<void> {
    await NestAPI.delete(`auth0/roles/${roleId}/users/${userId}`)
  }

  async addRoleUsers(roleID: string, auth0UserIds: string[]) {
    await NestAPI.post(`auth0/roles/${roleID}/assign/user`, auth0UserIds)
  }
  async removeRolesFromUser(auth0UserId: string, auth0Roles: Auth0Role[]): Promise<void> {
    await NestAPI.delete(`auth0/roles/remove/${auth0UserId}`, {
      data: {
        auth0Roles,
      },
    })
  }

  async addRolesToAuth0User(userId: number, auth0Roles: Auth0Role[]) {
    await NestAPI.post(`users/role/assign`, {
      userId,
      auth0Roles,
    })
  }

  async addRolesToPreSignupUser(email: string, auth0Roles: Auth0Role[]) {
    await NestAPI.post(`users/role/preassign`, {
      email,
      auth0Roles,
    })
  }

  // All Auth0 Permissions
  async getAllPermissions(): Promise<Auth0RolePermission[]> {
    const permissions: AxiosResponse<Auth0RolePermission[]> = await NestAPI.get(
      `auth0/roles/permissions`
    )
    return permissions.data
  }
}
