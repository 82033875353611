import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { UserWithBusiness } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'
import axios from 'axios'
import { NestAPI } from '@marketing-milk/frontend'
import Connect from 'app/redux/connect'
import { Connected } from '../../../../app/interface/redux'
import { CustomSelect } from '../shared/CustomSelect'

type AssignBusinessesProps = {
  mmUser: UserWithBusiness
  onSave: () => void
}

const AssignBusinesses = ({ mmUser, onSave, ...props }: AssignBusinessesProps & Connected) => {
  const [open, setOpen] = useState(false)
  const businesses = props.data.generic.businesses.data

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: { businesses: businesses.map(b => ({ label: b.businessName, value: b.id })) },
    // validationSchema: validationSchema,
    onSubmit: async values => {
      const businesses = values.businesses.map(v => v.value)

      setOpen(false)

      try {
        //TODO: we have two userService files: one in admin and one in frontend. Put this in frontend.
        await NestAPI.put(`users/${mmUser.id}/businesses`, {
          businesses,
        })

        toast.success('Updated user businesses')
        onSave()
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data) {
          e.response.data.errors.forEach(err => {
            toast.error(err.description)
          })
        } else {
          toast.error(`We could not update the user's businesses at this time`)
        }
      }
    },
  })

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        <span>Assign Businesses</span>
      </Button>

      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Assign Businesses</DialogTitle>
        <form className="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent>
            <CustomSelect
              options={formik.initialValues.businesses}
              onChange={value => formik.setFieldValue('businesses', value)}
              clearSelection={!open}
              placeHolderText="Business"
              fieldName="businesses"
              isMulti={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default Connect(AssignBusinesses)
