import { EGuard } from '@marketing-milk/frontend'
import { Dropdown, Label, Link } from 'app/interface/config/sidebar'
import { Environment } from '@marketing-milk/interfaces'

const isProdEnv = (process.env.REACT_APP_MM_ENV as Environment) === Environment.Production

// These icons come from https://fontawesome.com/search
export const SidebarLinks: (Dropdown | Label | Link)[] = [
  {
    guard: EGuard.Everyone,
    text: 'Marketing Milk',
    type: 'Label',
  },
  {
    guard: EGuard.Everyone,
    text: 'GoodData Dashboard',
    icon: 'chart-line',
    link: 'https://intelligence.marketingmilk.com',
    type: 'Link',
  },
  {
    guard: EGuard.AccessDashboardApp,
    text: 'Dashboards',
    icon: 'tachometer',
    link: `${process.env.REACT_APP_DASHBOARD}`,
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdBuilderApp,
    text: 'Ad Builder',
    icon: 'tools',
    link: `${process.env.REACT_APP_BUILDER}`,
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Support Desk',
    icon: 'question-circle',
    link: 'https://support.marketingmilk.com/portal/en/kb/marketing-milk',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Business Management',
    type: 'Label',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Businesses',
    icon: 'filter',
    link: 'business/beta/accounts/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Franchises',
    icon: 'briefcase',
    link: 'franchises/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Ownership Groups',
    icon: 'briefcase',
    link: 'ownership-groups/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Branches',
    icon: 'building',
    link: 'branches/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Tags',
    icon: 'hashtag',
    link: 'business/tags/list',
    type: 'Link',
  },
  {
    guard: EGuard.ReviewOfflineEventUpload,
    text: 'Upload Submissions',
    icon: 'file-signature',
    link: 'business/uploads/submissions',
    type: 'Link',
  },
  {
    guard: EGuard.ReviewBadDataRequest,
    text: 'Bad Data Submissions',
    icon: 'search',
    link: 'business/bad-data/submissions',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'User Management',
    type: 'Label',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Users',
    icon: 'users',
    link: 'users/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Pods',
    icon: 'user-friends',
    link: 'employee/pods/list',
    type: 'Link',
  },
  {
    guard: EGuard.AccessAdminApp,
    text: 'Notifications',
    icon: 'envelope',
    link: 'employee/roles/notifications',
    type: 'Link',
  },
  {
    guard: EGuard.ManageRoles,
    text: 'Roles',
    icon: 'shield',
    link: 'roles/list',
    type: 'Link',
  },
  {
    guard: EGuard.ManageRoles,
    text: 'Permissions',
    icon: 'shield',
    link: 'permissions/list',
    type: 'Link',
  },
  {
    guard: EGuard.ManageSupportDesk,
    text: 'Administration',
    type: 'Label',
  },
  {
    guard: EGuard.ManageSupportDesk,
    text: 'Manage Support Desk',
    icon: 'tasks',
    link: 'https://support.marketingmilk.com/support/marketingmilk/',
    type: 'Link',
  },
  {
    guard: EGuard.Everyone,
    text: 'Logout',
    icon: 'sign-out',
    link: 'logout',
    type: 'Link',
  },
]
