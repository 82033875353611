import Store from 'app/redux/store'
import Redux from 'app/interface/redux'
import { bindActions } from 'redux-zero/utils'
import { NestAPI } from '@marketing-milk/frontend'
import { Business } from '@marketing-milk/interfaces'
import { AxiosResponse } from 'axios'

const Master = bindActions(
  store => ({
    init: async (): Promise<void> => {
      const result: AxiosResponse<{ body: Business[] }> = await NestAPI.get('businesses')
      const state: Redux = store.getState()
      store.setState({
        data: {
          ...state.data,
          generic: {
            ...state.data.generic,
            businesses: {
              data: result.data.body.map(bus => ({ id: bus.id, businessName: bus.businessName })),
              loading: false,
              timestamp: +new Date(),
            },
          },
        },
      })
    },
  }),
  Store
)

export default Master
