import Store from 'app/redux/store'
import Redux from 'app/interface/redux'
import { bindActions } from 'redux-zero/utils'
import Branch from 'app/interface/data/branch'
import { NestAPI } from '@marketing-milk/frontend'
import { AxiosResponse } from 'axios'

const Master = bindActions(
  store => ({
    init: async (): Promise<void> => {
      const result: AxiosResponse<Branch[]> = await NestAPI.get('branches/all')
      const state: Redux = store.getState()
      store.setState({
        data: {
          ...state.data,
          generic: {
            ...state.data.generic,
            branches: {
              data: result.data,
              loading: false,
              timestamp: +new Date(),
            },
          },
        },
      })
    },
  }),
  Store
)

export default Master
