import Builder from './builder'
import { toast } from 'react-toastify'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Loading from 'components/message/loading'
import { RouteComponentProps } from 'react-router-dom'
import ErrorActions from 'app/redux/actions/core/errors'
import BusinessActions from 'app/redux/actions/pages/business'
import DatasetActions from 'app/redux/actions/data/funnel/dataset'
import { businessManagerService } from '../../../app/service/business-manager'
import { BusinessConfig } from '@marketing-milk/interfaces'

interface Props extends Redux, RouteComponentProps {}
interface State {
  loading: boolean
}

class Create extends PureComponent<Props> {
  state: State = {
    loading: true,
  }

  componentDidMount() {
    this.prepare()
  }

  prepare = () => {
    BusinessActions.initEmpty()
    DatasetActions.load()
    this.setState({
      loading: false,
    })
  }

  onSubmit = async () => {
    const {
      pages: { business },
    } = this.props
    let toastId
    try {
      toastId = toast.info(
        <span>
          Creating new business... <i className="fa fa-spinner fa-spin ml-2" />
        </span>,
        { autoClose: false }
      )
      // const result: any = await API.post('business/account/beta', business)
      // not worth here to attempt to detangle and solidify the redux interface. The Backend has very clear type-safe validation here
      const result = await businessManagerService.createBusiness(business as any as BusinessConfig)
      ErrorActions.clear()
      this.props.history.push(`/admin/business/beta/accounts/edit/${result.id}`)
      toast.update(toastId, {
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        render: 'Business has been created',
      })
    } catch (e: any) {
      if (e.response?.data?.data) {
        const { data } = e.response.data
        const columns = Object.keys(data)
        const errors = columns.map(x => ({
          column: x,
          message: Array.isArray(data[x]) ? `${x}: ${data[x][0]}` : data[x],
        }))
        ErrorActions.setErrors(errors)
      }
      toast.update(toastId, {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        render: 'Business could not be created',
      })
    }
  }

  render() {
    const { loading } = this.state
    return loading ? <Loading /> : <Builder onSubmit={this.onSubmit} new={true} />
  }
}

export default Connect(Create)
